@charset "UTF-8";

/* works-products
============================================================= */
.contents {
  &.-hasSide {
    @include mq-pc {
      width: (800 / 1440 * 100) + vw;
      min-height: 45.5vh;
      // padding-left: ((1200 / 3) / 1440 * 100) + vw;
    }
    @media screen and (min-width: $wide-basis) {
      // padding-left: ((1200 / 3) * 0.1) + rem;
    }
    @include mq-sp {
      // margin-top: (96 / 375 * 100) + vw;
      margin-top: 20.75vw;
    }
  }
  &.-list {
    display: flex;
    flex-wrap: wrap;
    // margin-left: -(34 / 1440 * 100) + vw;
    @include mq-pc {
      margin-right: -(34 / 1440 * 100) + vw;
    }
    @media screen and (min-width: $wide-basis) {
      margin-right: -3.4rem;
    }
  }
  &.-item {
    @include mq-pc {
      width: ((1200 / 3) / 1440 * 100) + vw;
      margin-bottom: 7.8rem;
    }
    @media screen and (min-width: $wide-basis) {
      width: ((1200 / 3) * 0.1) + rem;
    }
    @include mq-sp {
      &:not(:last-child) {
        margin-bottom: (48.25 / 375 * 100) + vw;
      }
    }
    // @include mq-pc {
    //   padding-right: (34 / 1440 * 100) + vw;
    // }
    // @media screen and (min-width: $wide-basis) {
    //   padding-right: 3.4rem;
    // }
    &:nth-child(even) {
      @include mq-pc {
        padding-left: (34 / 1440 * 100) + vw;
      }
      @media screen and (min-width: $wide-basis) {
        padding-left: 3.4rem;
      }
    }
    &:nth-child(odd) {
      @include mq-pc {
        padding-right: (34 / 1440 * 100) + vw;
      }
      @media screen and (min-width: $wide-basis) {
        padding-right: 3.4rem;
      }
    }
    &.-link {
      @include fadeover;
    } //link
  } //-item
  &.-txt {
    &.-wrap {
      display: flex;
      align-items: center;
    }
    &.-right {
      margin-left: auto;
    }
    .-name {
      @include mq-pc {
        font-size: 1.6rem;
        // margin-bottom: 1rem;
      }
      @include mq-sp {
        font-size: (16 / 375 * 100) + vw;
        margin-bottom: -(1.75 / 375 * 100) + vw;
      }
    }
    .-nameNo {
      color: #8c8c8c;
    }
  } //-txt
  &.-downLoad {
    img {
      width: 100%;
    }
    @include mq-pc {
      width: 20px;
    }
    @include mq-sp {
      width: (20 / 375 * 100) + vw;
    }
  }
  &.-img {
    @include mq-pc {
      margin-bottom: 1.7rem;
    }
    @include mq-sp {
      margin-bottom: (12 / 375 * 100) + vw;
    }
  } //-img
} //contents
