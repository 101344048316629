//--------------------------------------------------------------
// tsumiki用CSS_adjust
//
//
//
//
//--------------------------------------------------------------
@charset "UTF-8";
.-tsumiki {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  // div {
  //   @include mq-pc {
  //     margin-top: 8rem;
  //   }
  //   @include mq-sp {
  //     // margin-top: (55 / 375 * 100) + vw;
  //     margin-top: 12.75vw;
  //   }
  // } //div
  .tsumiki-Link {
    color: clr(gray);
  }
  figcaption {
    line-height: 1.5;
    @include mq-pc {
      font-size: 1.4rem;
    }
    @include mq-sp {
      font-size: (15 / 375 * 100) + vw;
      margin-bottom: (15 / 375 * 100) + vw;
    }
  }
  .tsumiki-images {
    &-large {
      width: 100%;
      img {
        width: 100%;
      }
    }
    &-medium {
      @include mq-pc {
        width: 50%;
      }
      @include mq-sp {
        width: 100%;
      }
    } //tsumiki-images-text
  }
} //-tsumiki
