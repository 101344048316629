@charset "UTF-8";

/* button
============================================================= */
.btn {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: $basefont;
  @include fadeover;
  @include mq-pc {
    max-width: 34.6rem;
  }
  .-txt {
    width: 100%;
    height: 100%;
    text-align: center;
    letter-spacing: 0.1em;
    @include mq-pc {
      font-size: 1.8rem;
      line-height: 1.667;
      padding: 10px 0px;
    }
    @include mq-sp {
      padding: (9.5 / 375 * 100) + vw 0;
      line-height: 1.875;
      font-size: (16 / 375 * 100) + vw;
    }
  }
  &.-form {
    @include mq-pc {
      max-width: 27rem;
    }
    @include mq-sp {
      width: 100%;
    }
    .-txt {
      @include mq-pc {
        padding: 23px 0;
      }
      @include mq-sp {
        padding: (20 / 375 * 100) + vw 0;
      }
    }
  }
  &.-white {
    color: clr(text);
    background-color: transparent;
    .-txt {
      border: 1px solid clr(btnGreen);
    }
    &.arrow {
      &:after {
        border-top: 1px solid clr(btnGreen);
        border-left: 1px solid clr(btnGreen);
        position: absolute;
      } //after
    }
    &:hover {
      opacity: 1 !important;
      .-txt {
        transition: all 0.5s ease;
        color: clr(white);
        border: 1px solid clr(btnGreen);
        background-color: clr(btnGreen);
      }
    } //hover
  } //white
  &.-green {
    color: clr(white);
    .-txt {
      border: 1px solid clr(btnGreen);
      background-color: clr(btnGreen);
    }
    &.arrow {
      &:after {
        border-top: 1px solid clr(white);
        border-left: 1px solid clr(white);
        position: absolute;
      } //after
    }
    &:hover {
      color: clr(text);
      .-txt {
        transition: all 0.5s ease;
        background-color: clr(white);
        color: black;
        border: 1px solid clr(btnGreen);
      }
      &.arrow {
        &:after {
          border-top: 1px solid clr(btnGreen);
          border-left: 1px solid clr(btnGreen);
          position: absolute;
        } //after
      }
    } //hover
    .-txt {
      color: clr(white);
    }
  } //black
  &.arrow {
    position: relative;
    text-decoration: none;
    align-items: center;
    display: flex;
    &:after {
      content: "";
      display: block;
      width: 5px;
      height: 5px;
      position: absolute;
    } //after
    &.-right::after {
      transform: rotate(135deg);
      right: 1rem;
    }
    &.-left::after {
      transform: rotate(-45deg);
      left: 1rem;
    }
  }
  &Wrap {
    display: flex;

    &.-double {
      @include mq-pc {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
      }
      @include mq-sp {
        margin-right: -(18 / 375 * 100) + vw;
        margin-left: -(18 / 375 * 100) + vw;
      }
      .btn {
        @include mq-pc {
          padding-right: 0.5rem;
          padding-left: 0.5rem;
        }
        @include mq-sp {
          padding-right: (18 / 375 * 100) + vw;
          padding-left: (18 / 375 * 100) + vw;
        }
      } //btn
      &.-column-reverseSp {
        @include mq-sp {
          flex-direction: column-reverse;
          .btn {
            margin-bottom: 3.467vw;
            &:first-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .btn {
      @include mq-pc {
        // margin-left: 0.75rem;
        // margin-right: 0.75rem;
      }
    } //btn
    &.-center {
      justify-content: center;
    }
    &.-left {
      justify-content: flex-start;
    }
  } //Wrap
} //btn

#js_formSubmit {
  &.-off {
    color: clr(text);
    background-color: transparent;
    .-txt {
      border: 1px solid clr(btnGreen);
      transition: all 0.3s ease 0s;
      // transition: background-color 1000ms linear;
    }
    &.arrow {
      &:after {
        border-top: 1px solid clr(btnGreen);
        border-left: 1px solid clr(btnGreen);
        position: absolute;
      } //after
    }
  }
  &.-on {
    opacity: 1 !important;
    color: clr(white);
    .-txt {
      color: clr(white);
      border: 1px solid clr(btnGreen);
      background-color: clr(btnGreen);
      transition: all 0.3s ease 0s;
      // transition: background-color 1000ms linear;
    }
    &.arrow {
      &:after {
        border-top: 1px solid clr(white);
        border-left: 1px solid clr(white);
        position: absolute;
      } //after
    }
  }
} //js_formSubmit
