@charset "UTF-8";

/* other
============================================================= */

/* pageLead */

.pageLead {
  letter-spacing: 0.008em;

  @include mq-pc {
    line-height: 1.23;
    padding-left: rem($pc-pdg);
    width: rem(524 + $pc-pdg);
    margin-top: rem(14);
  }

  @include mq-sp {
    margin-top: vw(15);
    line-height: 1.2;
    padding-left: vw($sp-pdg);
    padding-right: vw(88);
  }
}

/* tel */

.telLink {
  @include mq-pc {
    pointer-events: none;
    text-decoration: none;
  }
  &Txt {
    position: relative;
    @include mq-sp {
      &:after {
        width: 100%;
        background-color: clr(green);
        height: 1px;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
}

/* object fit image */

img.objectFit {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  &.-cover {
    font-family: "object-fit: cover;";
    object-fit: cover;
  }

  &.-contain {
    font-family: "object-fit: contain;";
    object-fit: contain;
  }
}

/* googleMap */

.googleMap {
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}

/* moreLink */
%readMoreAfterBeforeBase {
  content: "";
  display: inline-block;
  background-size: cover;
  position: relative;
  @include mq-pc {
    width: 100%;
    max-width: 9.4rem;
    height: 0.85rem;
    // width: (92 / 1440 * 100) + vw;
    // height: (8.5 / 1440 * 100) + vw;
  }
  @media screen and (min-width: $wide-basis) {
    // width: 9.2rem;
    // height: 0.85rem;
  }
  @include mq-sp {
    width: (94 / 375 * 100) + vw;
    height: (8.5 / 375 * 100) + vw;
  }
}
.readMore {
  a {
    display: flex;
    align-items: center;
  }
  .txt {
    white-space: nowrap;
    &.-ja {
      letter-spacing: 0.1em;
      @include mq-pc {
        font-size: 1.8rem;
      }
      @include mq-sp {
        font-size: (18 / 375 * 100) + vw;
      }
    }
    &.-en {
      letter-spacing: 0.05em;
      @include mq-pc {
        font-size: 1.5rem;
      }
      @include mq-sp {
        font-size: (15 / 375 * 100) + vw;
      }
    }
  }
  .-posRight_pc {
    @include mq-pc {
      justify-content: end;
    }
  }
  &Arrow {
    position: relative;
    &.-right {
      &:after {
        @extend %readMoreAfterBeforeBase;
        background: url(/assets/img/common/readMoreArrowRight.svg) no-repeat;
        @include mq-pc {
          margin-left: (17.5 / 1440 * 100) + vw;
        }
        @include mq-sp {
          margin-left: (17 / 375 * 100) + vw;
        }
        @media screen and (min-width: $wide-basis) {
          margin-left: 1.75rem;
        }
      }
    } //right
    &.-left {
      &:before {
        @extend %readMoreAfterBeforeBase;
        background: url(/assets/img/common/readMoreArrowLeft.svg) no-repeat;
        @include mq-pc {
          margin-right: (17 / 1440 * 100) + vw;
        }
        @include mq-sp {
          margin-right: (17 / 375 * 100) + vw;
        }
      }
    } //right
  } //Arrow
} //readMore
.moreLink {
  letter-spacing: 0.11em;
  line-height: 1;

  @include mq-pc {
    font-size: 1.4rem;
  }

  @include mq-sp {
    font-size: (13.5 / 375 * 100) + vw;
  }

  @include mq-hover {
    &.-right {
      a {
        &:hover {
          &::after {
            width: 5.5rem;
            right: auto;
            left: 0;
          }
        }
      }
    }
    &.-bottom {
      a {
        &:hover {
          &::after {
            width: 9rem;
            right: auto;
            left: 0;
          }
        }
      }
      &.-small {
        a {
          &:hover {
            &::after {
              width: 4.3rem !important;
            }
          }
        }
      }
      &.-map {
        a {
          &:hover {
            &::after {
              width: 3.3rem !important;
            }
          }
        }
      }
      &.-tell {
        a {
          &:hover {
            &::after {
              width: 10.9rem !important;
            }
          }
        }
      }
    } //bottom
    a {
      &:hover {
        &::before {
          width: 0;
        }
      }
      &.-bottomTxt {
        &:hover {
          &::after {
            width: 9rem;
          }
        }
        &.-smallTxt {
          &:hover {
            &::after {
              width: 4.3rem !important;
            }
          }
        }
        &.-mapTxt {
          &:hover {
            &::after {
              width: 3.3rem !important;
            }
          }
        }
        &.-tellTxt {
          &:hover {
            &::after {
              width: 10.9rem !important;
            }
          }
        }
      } //bottomTxt
      &.-rightTxt {
        &:hover {
          &::after {
            width: 5.5rem;
          }
        }
      } //rightTxt
    } //a
  }
  &.-right {
    .moreLinkTxt {
      @include mq-pc {
        padding-left: 7.2rem;
      }
      @include mq-sp {
        padding-left: (68 / 375 * 100) + vw;
      }
      &::after {
        @include mq-pc {
          left: 5.5rem;
          width: 0;
        }
      } //after
    } //moreLinkTxt
  } //right
  &.-bottom {
    height: 2.3rem;
    .moreLinkTxt {
      @include mq-pc {
        padding-bottom: 3rem;
      }
      @include mq-sp {
        padding-bottom: (30 / 375 * 100) + vw;
      }
      &::after {
        @include mq-pc {
          left: 9rem;
          width: 0;
        }
      } //after
    } //moreLinkTxt
    &.-small {
      display: inline-block;
      height: auto;
      .moreLinkTxt {
        @include mq-pc {
          padding-bottom: 2rem;
        }
        @include mq-sp {
          padding-bottom: (15 / 375 * 100) + vw;
        }
        &::after {
          @include mq-pc {
            left: 0 !important;
          }
        } //after
      }
    } //small
    &.-map {
      display: inline-block;
      height: auto;
      .moreLinkTxt {
        @include mq-pc {
          padding-bottom: 2rem;
        }
        @include mq-sp {
          padding-bottom: (15 / 375 * 100) + vw;
        }
        &::after {
          @include mq-pc {
            left: 0 !important;
          }
        } //after
      }
    } //map
    &.-tell {
      height: auto;
      .moreLinkTxt {
        @include mq-pc {
          padding-bottom: 2rem;
        }
        @include mq-sp {
          padding-bottom: (15 / 375 * 100) + vw;
        }
        &::after {
          @include mq-pc {
            left: 0 !important;
          }
        } //after
      }
    } //tell
  } //bottom

  .moreLinkTxt {
    display: inline-block;
    position: relative;
    text-decoration: none;
    letter-spacing: 0.1em;
    &::before,
    &::after {
      background-color: clr(gray);
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 1px;
    }
    &::before {
      left: 0;
      @include mq-hover {
        transition-duration: 0.3s;
      }
    }

    &::after {
      @include mq-pc {
        width: 0;
      }

      @include mq-hover {
        transition-duration: 0.4s;
        transition-delay: 0.2s;
      }

      @include mq-sp {
        display: none;
      }
    }
  } //moreLinkTxt
  .-rightLink::before {
    @include mq-pc {
      width: 5.5rem;
    }
    @include mq-sp {
      width: (55 / 375 * 100) + vw;
    }
  } //rightLink
  .-bottomLink::before {
    @include mq-pc {
      width: 9rem;
    }
    @include mq-sp {
      width: (90 / 375 * 100) + vw;
    }
  } //bottomLink
  .-smallLink::before {
    @include mq-pc {
      width: 4.3rem;
    }
    @include mq-sp {
      width: (43 / 375 * 100) + vw;
    }
  } //smallLink
  .-mapLink::before {
    @include mq-pc {
      width: 3.3rem;
    }
    @include mq-sp {
      width: (35 / 375 * 100) + vw;
    }
  } //mapLink
  .-tellLink::before {
    @include mq-pc {
      width: 10.9rem;
    }
    @include mq-sp {
      width: (110 / 375 * 100) + vw;
    }
  } //tellLink
} //moreLink
.moreLinkFade {
  display: inline-block;
  &Txt {
    position: relative;
    line-height: 1;
    letter-spacing: 0.1em;
    &:after {
      position: absolute;
      content: "";
      bottom: -0.5rem;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 2px;
      background-color: clr(green);
      transition-duration: 0.3s;
    }
    &.-moreLinkFadeTxtLink {
      &:hover {
        &:after {
          opacity: 0;
        }
      }
    } //moreLinkFadeTxtLink
    &.-smallTxtLink {
      &:after {
        bottom: -0.25rem;
      }
    }
  } //txt
} //moreLinkFade
%-underLink {
  position: relative;
  line-height: 1;
  &:after {
    position: absolute;
    content: "";
    bottom: -0.8rem;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 1px;
    background-color: clr(green);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
}
.categoryItem a {
  &:hover {
    @extend %-underLink;
  }
}
.-currentUnder {
  @extend %-underLink;
}
.-underLink {
  @extend %-underLink;
}

/* figure */

.figure {
  position: relative;

  figcaption {
    position: absolute;
    top: 0;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    transform: rotate(180deg);

    @include mq-pc {
      font-size: rem(11);
      left: calc(-1em + #{rem(-11)});
    }

    @include mq-sp {
      font-size: vw(10);
      left: calc(-1em + #{vw(-11)});
    }
  }

  &.-captionRightBottom {
    figcaption {
      bottom: 0;
      top: auto;
      left: auto;

      @include mq-pc {
        right: calc(-1em + #{rem(-11)});
      }

      @include mq-sp {
        right: calc(-1em + #{vw(-11)});
      }
    }
  }

  &.-captionRightTop {
    figcaption {
      left: auto;

      @include mq-pc {
        right: calc(-1em + #{rem(-11)});
      }

      @include mq-sp {
        right: calc(-1em + #{vw(-11)});
      }
    }
  }
}

/* paragraphWrap */

.paragraphWrap {
  > p {
    &:not(:last-child) {
      @include mq-pc {
        margin-bottom: rem(20);
      }

      @include mq-sp {
        margin-bottom: vw(16);
      }
    }
  }
}
/* link underLine */
.-link {
  @include fadeover;
}
.-underLine {
  border-bottom: 1px solid clr(gray);
  &.-black {
    border-color: black;
  }
  &.-green {
    border-color: clr(green);
  }
}
/* 縦組み */
.-vertical-rl {
  writing-mode: vertical-rl;
}
// telボタン
/*pcの時*/
@include mq-pc {
  a[href^="tel:"] {
    pointer-events: none;
  }
}
@include mq-sp {
  /*spの時*/
  a[href^="tel:"] {
    pointer-events: auto;
  }
}
