@charset "UTF-8";

/* title
============================================================= */

.pageTitle {
  letter-spacing: 0.1em;
  line-height: 1;
  &.__en {
    // font-family: $enfont;
  }
  &.__titleLevel {
    &01 {
      @include mq-pc {
        font-size: 2.4rem;
      }
      @include mq-sp {
        font-size: (22 / 375 * 100) + vw;
      }
    }
  }
}

.section__title {
  letter-spacing: 0.1em;
  // line-height: 1;
  &.__en {
    // font-family: $enfont;
  }
  &.__titleLevel {
    &01 {
      font-weight: 600;
      @include mq-pc {
        font-size: 2.4rem;
        margin-bottom: 6.2rem;
      }
      @include mq-sp {
        font-size: (22 / 375 * 100) + vw;
        margin-bottom: (26 / 375 * 100) + vw;
      }
    }
    &02 {
      font-weight: 600;
      @include mq-pc {
        font-size: 2rem;
      }
      @include mq-sp {
        font-size: (18 / 375 * 100) + vw;
      }
    }

    &03 {
      @include mq-pc {
        font-size: 2rem;
      }
      @include mq-sp {
        font-size: (13 / 375 * 100) + vw;
      }
    }
    &04 {
      @include mq-pc {
        font-size: 2.2rem;
        margin-bottom: 7.3rem;
      }
      @include mq-sp {
        font-size: (17 / 375 * 100) + vw;
        margin-bottom: (48 / 375 * 100) + vw;
      }
    }
  }
  &.__mbNon {
    margin-bottom: 0;
  }
}
