@charset "UTF-8";

/* ------------------------------------------------------------------
 body container wrapper main
------------------------------------------------------------------ */
.l-wrapper {
  // background-color: pink;
}
#header {
  // background-color: red !important;
}
// wrapper
.l-wrapper {
  position: relative;
  // @include mq-pc {
  //   padding-top: 7.8rem;
  // }
  // @include mq-sp {
  //   padding-top: 15vw;
  //   // margin-top: (78 / 375 * 100) + vw;
  // }
}
// container
.l-container {
  // overflow: hidden;
  position: relative;
  width: 100%;
  height: auto;
  // min-height: 100%;
  box-sizing: border-box;
  // @include mq-pc {
  //   padding-bottom: 17.5rem;
  // }

  // @include mq-sp {
  //   padding-bottom: 36.533vw;
  // }
}
// main
.l-main {
  position: relative;
  max-width: $wrapper-inner;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @include mq-sp {
    max-width: unset;
  }
}
.l-article {
}
.section__l__inner {
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  width: 100%;
  @include mq-pc {
    max-width: $content-inner;
  }
  @include mq-sp {
    padding-right: $padding_rl-sp;
    padding-left: $padding_rl-sp;
  }
  @media screen and (min-width: $wide-basis) {
    max-width: $content-inner-wide;
  }
}
.section__medium__inner {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  @include mq-pc {
    max-width: $mediumContent-inner;
  }
  @include mq-sp {
    padding-right: $padding_rl-sp;
    padding-left: $padding_rl-sp;
  }
  @media screen and (min-width: $wide-basis) {
    max-width: $mediumContent-inner-wide;
  }
}
.section__small__inner {
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  width: 100%;
  @include mq-pc {
    max-width: $smallContent-inner-wide;
  }
  @include mq-sp {
    // padding-right: $padding_rl-sp;
    // padding-left: $padding_rl-sp;
  }
  @media screen and (min-width: $wide-basis) {
    max-width: $smallContent-inner-wide;
  }
  &.-left {
    @media screen and (min-width: 898px) {
      margin-left: unset;
    }
    @media screen and (min-width: $min-basis) and (max-width: 897px) {
      max-width: 100%;
    }
  } //left
}
.section__pageNav__inner {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  @include mq-pc {
    max-width: $pageNav-inner;
  }
  @include mq-sp {
    padding-right: $padding_rl-sp;
    padding-left: $padding_rl-sp;
  }
  @media screen and (min-width: $wide-basis) {
    max-width: $pageNav-inner-wide;
  }
}
.section__cateNav__inner {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  @include mq-pc {
    max-width: $cateNav-inner;
  }
  @include mq-sp {
    padding-right: $padding_rl-sp;
    padding-left: $padding_rl-sp;
  }
  @media screen and (min-width: $wide-basis) {
    max-width: $cateNav-inner-wide;
  }
}
