@charset "UTF-8";

/* navigation
============================================================= */

/* gNav */

.gnavWrap {
  @include mq-pc {
    height: 100%;
  }
}

.gNav {
  display: flex;
  @include mq-pc {
    justify-content: end;
    height: 100%;
  }
  @include mq-sp {
    // flex-wrap: wrap;
    flex-direction: column;
  }
  > li {
    letter-spacing: 0.1em;
    position: relative;
    // font-weight: 600;
    line-height: 1;
    order: 1;
    @include mq-pc {
      font-size: 1.6rem;
    }
    @media screen and (min-width: ($min-basis + 1)) and (max-width: 820px) {
      font-size: 1.4rem;
    }
    @include mq-sp {
      font-size: (16 / 375 * 100) + vw;
    }
    &:not(:last-child) {
      @include mq-sp {
        margin-bottom: (50 / 375 * 100) + vw;
      }
    }
    &:last-child {
      @include mq-sp {
        padding-bottom: (50 / 375 * 100) + vw;
      }
    }

    > a {
      text-decoration: none;
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      @include mq-pc {
        height: 100%;
      }
      @include mq-hover {
        &:hover {
          &::after {
            width: rem(10);
          }
        }
      }
    }

    &.-active,
    &.-activeParent {
      > a {
        position: relative;

        &::after {
        }
      }

      &:not(.-spChildList) {
        a {
          // pointer-events: none;
        }
      }
    }

    &.-spChildList {
      > a {
        // padding-right: vw(41);
        .linkArrow {
          margin-left: 1rem;
          &.__Bottom {
            display: none;
          }
        }
      }
    }

    &.-spChildList.-open {
      > a {
        .linkArrow {
          margin-left: 1rem;
          &.__Right {
            display: none;
          }
          &.__Bottom {
            display: inline-block;
          }
        }
      }
    }

    &.-spChildList.-active,
    &.-spChildList.-activeParent {
      > a {
        &::after {
          transition-duration: 0s;
        }
      }
    }
  }
  &Item {
    @include mq-pc {
      &:not(:last-child) {
        margin-right: (52 / 1440 * 100) + vw;
      }
    }
    @include mq-sp {
      margin-left: -(20 / 375 * 100) + vw;
    }
    &.-adjust {
      @include mq-sp {
        margin-bottom: (30 / 375 * 100) + vw !important;
      }
    }
  } //Item
}

/* gNavChild */
.gNavChild {
  .gNavChildItem {
    @include mq-sp {
      margin-left: (20 / 375 * 100) + vw;
    }
    &:not(:last-child) {
      @include mq-sp {
        margin-bottom: (22 / 375 * 100) + vw;
      }
    }
  }
  .gNavChildItemWrap {
    @include mq-sp {
      margin-top: (30 / 375 * 100) + vw;
    }
  } //gNavChildItemWrap
  &.-active {
    &:before {
      display: none;
    }
  }
  @at-root {
    .-open & {
      display: block;
    }
  }

  li {
    font-weight: 300;
    letter-spacing: 0.045em;
    position: relative;
    display: flex;
    align-items: center;
    @include fadeover;
    &:hover {
      cursor: pointer;
    }
    @include mq-pc {
      font-size: 1.3rem;
    }
    @include mq-sp {
      font-size: 3.333vw;
    }

    a {
      position: relative;
      text-decoration: none;

      // color: #777;
      position: relative;

      @include mq-hover {
        &:hover {
          &::after {
          }
        }
      }

      &::after {
        @include mq-hover {
          transition-duration: 0.3s;
        }
      }
    }

    &.-active {
      a {
        pointer-events: none;

        &::after {
          @include mq-pc {
          }
        }
      }
    }
  }
} //gNavChild

/* languageNav */

.languageNav {
  display: flex;
  position: relative;
  z-index: 1000000;
  color: #8c8c8c;
  margin-left: auto;
  @include mq-pc {
    // margin-top: 0.5rem;
  }
  @include mq-sp {
    margin-top: -1vw;
    display: none;
  }
  > li {
    font-weight: 300;
    line-height: 1;
    font-family: $enfont;
    position: relative;
    &:not(:last-child) {
      // border-right: 1px solid clr(text);
      &:after {
        display: inline-block;
        content: "";
        transform: rotate(20deg);
        width: 1px;
        height: 100%;
        background-color: #8c8c8c;
        position: absolute;
        right: 0;
      }
    }
    @include mq-pc {
      font-size: 1.32rem;
      padding-left: 1.1rem;
      padding-right: 1.1rem;
    }
    @include mq-sp {
      font-size: 3.667vw;
      padding-left: 2.133vw;
      padding-right: 2.133vw;
      height: (12 / 375 * 100) + vw;
    }

    &.-active,
    &.-activeParent {
      a {
        position: relative;
        pointer-events: none;
        font-weight: normal;
        color: clr(text);
        &::after {
          transition-duration: 0s;
        }
      }
    }

    a {
      position: relative;
      text-decoration: none;
      color: #8c8c8c;
      transition-duration: 0.3s;
      white-space: nowrap;
      @include mq-hover {
        &:hover {
          color: clr(text);
        }
      }

      // &::after {
      //   content: "";
      //   display: block;
      //   width: 0;
      //   height: 1px;
      //   position: absolute;
      //   background-color: #1a1a1a;
      //   bottom: vw(-11);

      //   @include mq-hover {
      //     transition-duration: 0.3s;
      //   }
      // }
    }
  }
}
.-spMenuOpen {
  .languageNav {
    @include mq-sp {
      display: inline-flex;
    }
  }
}
.-spMenuOpenItem {
  @include mq-pc {
    margin-top: 10rem;
  }
  @include mq-sp {
    margin-top: (89 / 375 * 100) + vw;
    display: flex;
    justify-content: end;
    padding-left: $content_inner_rl-sp;
    padding-right: $content_inner_rl-sp;
    position: fixed;
    bottom: (35 / 375 * 100) + vw;
    width: 100%;
    align-items: center;
  }
  .languageNav {
    margin-right: auto;
    margin-left: unset;
  }
  .sns {
    margin-left: auto;
    display: flex;
    img {
      width: 100%;
      height: 100%;
    }
    a {
      transition-duration: 0.3s;
      &:hover {
        opacity: 0.7;
        transition-duration: 0.3s;
      }
    }
  }
  .-insta {
    width: 100%;
    overflow: hidden;
    @include mq-pc {
      margin-right: 3.5rem;
      max-width: 2.475rem;
      max-height: 2.475rem;
    }
    @include mq-sp {
      margin-right: 7.467vw;
      max-width: 6.072vw;
      max-height: 6.072vw;
    }
  }
  .-fb {
    width: 100%;
    overflow: hidden;
    @include mq-pc {
      max-width: 1.16rem;
      max-height: 2.5rem;
    }
    @include mq-sp {
      max-width: 2.845vw;
      max-height: (23 / 375 * 100) + vw;
    }
  }
} //spMenuOpenItem

.worksChild {
  @include mq-pc {
    display: none !important;
    top: 0;
    width: 100%;
    background-color: clr(bgBeigeColor);
    position: fixed;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    display: flex;
    padding: 7.2rem 0;
    margin-top: $headerH_pc;
    &.-relativGnavChild {
      position: relative !important;
      margin-top: 0 !important;
    } //fixedworksChild
    .worksChildInner {
      max-width: $content-inner-wide;
      padding-left: $content-inner-rl-wide;
      padding-right: $content-inner-rl-wide;
      margin-right: auto;
      margin-left: auto;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .worksChildTitle {
      font-size: 2.4rem;
    }
    .worksChildItemWrap {
      // background-color: pink;
      display: flex;
      width: 100%;
      justify-content: space-between;
      @include mq-pc {
        max-width: 70.7rem;
      }
      @media screen and (min-width: $wide-basis) {
        max-width: 70.7rem;
        width: 100%;
      }
      @media screen and (min-width: $min-basis) and (max-width: 980px) {
        width: (1000 / 1440 * 100) + vw;
      }
    }
    .worksChildItem {
      // max-width: (346 / 1440 * 100) + vw;
      max-width: 34.6rem;
      display: inline-flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:not(:last-child) {
        @include mq-pc {
          margin-right: (15 / 1440 * 100) + vw;
        }
        @media screen and (min-width: $wide-basis) {
          margin-right: 1.5rem;
        }
      }

      &:hover {
        opacity: 1;
      }
      a {
        font-size: 1.8rem;
        border: 1px solid clr(green);
        line-height: 1.66666;
        padding: 10px 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          opacity: 1;
          color: white;
          background-color: clr(green);
        }
      }
    } //worksChildItem
    display: none;
  } //pc

  @include mq-sp {
    margin-top: (20 / 375 * 100) + vw;
    margin-left: (20 / 375 * 100) + vw;
  }
}
