@charset "UTF-8";

/* footer
============================================================= */

#footer {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  @include mq-pc {
    // padding-bottom: 8.6rem;
    padding-bottom: (46 / 1440 * 100) + vw;
    margin-top: 16.8rem;
  }
  @include mq-sp {
    padding-bottom: (52 / 375 * 100) + vw;
    margin-top: (80 / 375 * 100) + vw;
  }
  @media screen and (min-width: $wide-basis) {
    padding-bottom: 4.6rem;
  }
  .footer__inner {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @include mq-pc {
      max-width: $content-inner;
      // padding-left: $content-inner-rl;
      // padding-right: $content-inner-rl;
    }
    @include mq-sp {
      // padding-right: $padding_rl-sp;
      // padding-left: $padding_rl-sp;
      padding-right: $mv_rl-sp;
      padding-left: $mv_rl-sp;
    }
    @media screen and (min-width: $wide-basis) {
      width: 100%;
      max-width: $content-inner-wide;
      // padding-left: $content-inner-rl-wide;
      // padding-right: $content-inner-rl-wide;
    }
  } //footer__inner
}

.footer__logo {
  font-weight: 500;
  white-space: nowrap;
  @include mq-pc {
    margin-bottom: 4.7rem;
  }
  @media screen and (min-width: $min-basis) and (max-width: 980px) {
    margin-bottom: (52 / 1440 * 100) + vw;
  }
  @media screen and (min-width: $wide-basis) {
    margin-bottom: 4.7rem;
  }
  @include mq-sp {
    margin-bottom: (30 / 375 * 100) + vw;
  }
  a {
    color: clr(link);
    text-decoration: none;
    display: inline-block;
    &:hover {
      @include fadeover;
    }
  }
  img {
    width: 100%;
    @include mq-pc {
      // width: (311.51 / 1440 * 100) + vw;
      // width: 31.151rem;
      max-width: 31.151rem;
    }
    @media screen and (min-width: $min-basis) and (max-width: 1140px) {
      width: (311.51 / 1440 * 100) + vw;
    }

    @media screen and (min-width: $wide-basis) {
      max-width: 31.151rem;
      width: 100%;
    }
    @include mq-sp {
      width: (249.21 / 375 * 100) + vw;
    }
  }
}
.footer__contents {
  display: flex;
  width: 100%;
  @include mq-pc {
    // padding-left: $content-inner-rl;
    // padding-right: $content-inner-rl;
  }
  @media screen and (min-width: $wide-basis) {
    // margin-left: $content-inner-rl-wide;
    // margin-right: $content-inner-rl-wide;
  }
  @include mq-sp {
    flex-wrap: wrap;
  }
  &Left {
    // background-color: rosybrown;
    @include mq-pc {
      max-width: 31.151rem;
    }
    @media screen and (min-width: $min-basis) and (max-width: 980px) {
      width: (331.51 / 1440 * 100) + vw;
      // +5px数値上げる
    }
    @include mq-sp {
      width: 100%;
      order: 2;
      margin-top: (55 / 375 * 100) + vw;
      // padding-left: $content-inner-rl-wide;
    }
  } //Left
  &Right {
    // background-color: pink;
    @include mq-pc {
      padding-top: 1.6rem;
      flex: 1;
      padding-left: (161.5 / 1440 * 100) + vw;
    }
    @media screen and (min-width: $wide-basis) {
      padding-left: 16.15rem;
    }
    @include mq-sp {
      order: 1;
    }
  } //Right
} //footer__contents

.footer__nav {
  &Wrap {
    display: flex;
    @media screen and (min-width: 981px) {
      justify-content: end;
    }
    @media screen and (min-width: $min-basis) and (max-width: 980px) {
    }
  }
  &List {
    display: flex;
    @include mq-sp {
      flex-wrap: wrap;
      flex-direction: column;
    }
    &.-left {
      @include mq-sp {
        margin-right: (34 / 375 * 100) + vw;
      }
      .footer__navItem {
        white-space: nowrap;
        &:not(:first-child) {
          @include mq-sp {
            margin-top: (30 / 375 * 100) + vw;
          }
        }
      } //footer__navItem
    } // -left
    &.-right {
      @include mq-pc {
        display: flex;
        flex-direction: column;
        margin-left: (72 / 1440 * 100) + vw;
      }
      @media screen and (min-width: $wide-basis) {
        margin-left: 7.2rem;
      }

      .footer__navItem:not(:first-child) {
        @include mq-pc {
          margin-top: 2.4rem;
        }
        @include mq-sp {
          margin-top: (30 / 375 * 100) + vw;
        }
      } //footer__navItem
    } // -right
  }
  &Item {
    &.-works {
      @include mq-pc {
        margin-left: (50 / 1440 * 100) + vw;
      }
      @media screen and (min-width: $wide-basis) {
        margin-left: 5rem;
      }
    }
    &.-company {
      @include mq-pc {
        margin-left: (76 / 1440 * 100) + vw;
      }
      @media screen and (min-width: $wide-basis) {
        margin-left: 7.6rem;
      }
    }
  }
  &Title {
    line-height: 1;
    color: clr(text);
    display: block;
    white-space: nowrap;
    @include mq-pc {
      font-size: 1.5rem;
      // font-size: (15 / 1440 * 100) + vw;
    }
    @media screen and (min-width: $min-basis) and (max-width: 980px) {
      font-size: (20 / 1440 * 100) + vw;
    }
    @include mq-sp {
      font-size: (15 / 375 * 100) + vw;
    }
  } //Title
  &-child {
    @include mq-pc {
      margin-top: 1.5rem;
    }
  }
  &-childItem {
    @include mq-pc {
      font-size: 1.3rem;
      // font-size: (15 / 1440 * 100) + vw;
      line-height: 1.6;
      &:not(:first-child) {
        margin-top: 1.2rem;
      }
    }
    @media screen and (min-width: $min-basis) and (max-width: 980px) {
      font-size: (20 / 1440 * 100) + vw;
    }

    @include mq-sp {
      margin-top: (20 / 375 * 100) + vw;
      font-size: (15 / 375 * 100) + vw;
      margin-left: (10 / 375 * 100) + vw;
      line-height: 1;
      &:not(:last-child) {
        margin-bottom: (10 / 375 * 100) + vw;
      }
    }
    a {
      // color: clr(gray);
    }
  } //childItem
}
.footer__address {
  line-height: 1.6;
  letter-spacing: 0.01em;
  @include mq-pc {
    font-size: 1.5rem;
    // font-size: (15 / 1440 * 100) + vw;
  }
  @media screen and (min-width: $min-basis) and (max-width: 980px) {
    font-size: (20 / 1440 * 100) + vw;
  }
  @include mq-sp {
    font-size: (15 / 375 * 100) + vw;
  }
}
.footer__map {
  @include mq-pc {
    margin-top: 1.4rem;
  }
  @media screen and (min-width: $min-basis) and (max-width: 980px) {
    margin-top: (19 / 1440 * 100) + vw;
  }
  @include mq-sp {
    margin-top: (14 / 375 * 100) + vw;
    margin-bottom: (40 / 375 * 100) + vw;
  }
  a {
    letter-spacing: 0.05em;
    @include mq-pc {
      font-size: 1.4rem;
    }
    @media screen and (min-width: $min-basis) and (max-width: 980px) {
      font-size: (20 / 1440 * 100) + vw;
    }
    @include mq-sp {
      font-size: (15 / 375 * 100) + vw;
    }
  }
}
.footer__privacy {
  @include mq-pc {
    text-align: right;
    padding-left: $content-inner-rl;
    padding-right: $content-inner-rl;
  }
  .footer__privacyItem {
    line-height: 1;
    @include mq-pc {
      font-size: 1.2rem;
    }
    @include mq-sp {
      font-size: (12/ 375 * 100) + vw;
    }
  } //footer__privacyItem
} //footer__privacy
.footer__end {
  position: relative;
  @include mq-pc {
    justify-content: right;
    font-size: rem(110);
    display: flex;
    // margin-top: 2.8rem;
    padding-left: $content-inner-rl;
    padding-right: $content-inner-rl;
  }
  @media screen and (min-width: $min-basis) and (max-width: 980px) {
    // margin-top: (31 / 1440 * 100) + vw;
  }
  @media screen and (min-width: $wide-basis) {
    padding-left: $content-inner-rl-wide;
    padding-right: $content-inner-rl-wide;
  }
  @include mq-sp {
    text-align: center;
    // margin-top: (45 / 375 * 100) + vw;
    // padding-right: $wrapper_inner_rl-sp;
    // padding-left: $wrapper_inner_rl-sp;
  }

  .privacyLink {
    @include mq-sp {
      font-size: 2.933vw;
      margin-top: 5.333vw;
      margin-bottom: 5.333vw;
    }
  }
  .footer__copyright {
    line-height: 1;
    @include mq-pc {
      font-size: 1.2rem;
      text-align: right;
    }
    @media screen and (min-width: $min-basis) and (max-width: 980px) {
      font-size: (17 / 1440 * 100) + vw;
    }
    @include mq-sp {
      font-size: (11 / 375 * 100) + vw;
      text-align: left;
    }
  }
  .footerLogoItem {
    a {
      display: block;
      @include fadeover;
    }
    img {
      width: 18.667vw;
    }
  }
}

.footer__pageTop {
  position: absolute;
  display: inline-block;
  @include mq-pc {
    top: 0;
    // bottom: 4.75rem;
    right: 6.7rem;
    width: 1.6rem;
  }
  @include mq-sp {
    width: (16 / 375 * 100) + vw;
    bottom: (53 / 375 * 100) + vw;
    right: (34 / 375 * 100) + vw;
  }
  a {
    display: flex;
  }
}
