@charset "UTF-8";

/* ------------------------------------------------------------------
 commonLayout
------------------------------------------------------------------ */
.-commonLayoutImgTxtWrap {
  display: flex;
  // max-width: 107.5rem;
  margin: auto;
  width: 100%;
  @include mq-sp {
    flex-wrap: wrap;
  }
  &.-right {
    justify-content: end;
    .-commonLayoutImgBox {
      @include mq-pc {
        margin-left: $topContent_rl-pc;
      }
      @media screen and (min-width: $wide-basis) {
        margin-left: $topContent_rl-wide;
      }
    } //commonLayoutImgBox
  } //-right
  &.-left {
    justify-content: start;
    .-commonLayoutImgBox {
      @include mq-pc {
        margin-right: $topContent_rl-pc;
      }
      @media screen and (min-width: $wide-basis) {
        margin-right: $topContent_rl-wide;
      }
    } //commonLayoutImgBox
  } //-left
  .-commonLayoutTxtBox {
    @include mq-pc {
      width: (446 / 1440 * 100) + vw;
    }
    // @media screen and (min-width: $wide-basis) {
    //   max-width: 44.6rem;
    //   width: 100%;
    // }
  } //commonLayoutTxtBox
  .-commonLayoutImgBox {
    @include mq-pc {
      width: (713 / 1440 * 100) + vw;
    }
    @include mq-sp {
      margin-top: (55 / 375 * 100) + vw;
    }
  } //commonLayoutImgBox
  &.-spReverse {
    @include mq-sp {
      flex-wrap: wrap-reverse;
    }
  } //spReverse
} //commonLayoutImgTxtWrap
.-commonMv {
  position: relative;
  @include mq-pc {
    display: flex;
  }
  @include mq-sp {
    width: 100%;
    position: relative;
    display: block;
  }
  .-commonMvTxt {
    @include mq-pc {
      padding-left: (182 / 1440 * 100) + vw;
      width: (509 / 1440 * 100) + vw;
    }
    @include mq-sp {
      padding-right: $content_inner_rl-sp;
      margin-bottom: (50 / 375 * 100) + vw;
    }
    &List {
      display: flex;
      @include mq-sp {
        height: 100%;
        justify-content: end;
        padding-top: (40 / 375 * 100) + vw;
        flex-direction: row-reverse;
      }
    }
    &Item {
      writing-mode: vertical-rl;
      line-height: 1;
      @include mq-pc {
        letter-spacing: 0.35em;
      }
      @include mq-sp {
        letter-spacing: 0.5em;
        font-size: (16 / 375 * 100) + vw;
      }
      &:not(:last-child) {
        @include mq-pc {
          margin-right: 2.8rem;
        }
        @include mq-sp {
          margin-left: (30 / 375 * 100) + vw;
        }
      }
    } //Item
  } //commonMvTxt
  .-commonMvImgWrap {
    @include mq-pc {
      flex: 1;
    }
    @include mq-sp {
      width: 100%;
    }
    &.-spPaddingRight {
      @include mq-sp {
        padding-right: $mv_rl-sp;
      }
    } //spPaddingRight
  } //commonMvImgWrap
} //commonMv
.section__common {
  @include mq-sp {
    padding-right: $content_inner_rl-sp;
    padding-left: $content_inner_rl-sp;
  }
} //section__common
.section__detailCon {
  .detailList {
    border-top: 1px solid clr(border);
    @include mq-pc {
      padding-top: 3rem;
    }
    @include mq-sp {
      padding-top: (8 / 375 * 100) + vw;
    }
  }
  .detailItem {
    display: flex;
    border-bottom: 1px solid clr(border);
    font-weight: 500;
    @include mq-pc {
      padding-bottom: 2.7rem;
      letter-spacing: 0.05em;
    }
    @include mq-sp {
      flex-wrap: wrap;
      font-size: (15 / 375 * 100) + vw;
      padding-bottom: (8 / 375 * 100) + vw;
      line-height: 1.8;
    }
    &:not(:last-child) {
      @include mq-pc {
        margin-bottom: 2.7rem;
      }
      @include mq-sp {
        margin-bottom: (8 / 375 * 100) + vw;
      }
    }
  } //detailItem
  .detailHead {
    @include mq-pc {
      width: (124 / 1440 * 100) + vw;
      white-space: nowrap;
    }
    @media screen and (min-width: $wide-basis) {
      max-width: 12.4rem;
      width: 100%;
    }
    @include mq-sp {
      width: 100%;
      letter-spacing: 0.01em;
      font-size: (15 / 375 * 100) + vw;
    }
    &.-small {
      @include mq-sp {
        font-size: (12 / 375 * 100) + vw;
      }
    }
  } //detailHead
  .detailTxt {
    flex: 1;
    @include mq-pc {
      padding-left: (27 / 1440 * 100) + vw;
    }
    @media screen and (min-width: $wide-basis) {
      padding-left: 2.7rem;
    }
    @include mq-sp {
      width: 100%;
      letter-spacing: 0.1em;
      margin-top: -(10 / 1440 * 100) + vw;
    }
  } //detailTxt
} //section__detailCon
.section__pageNav {
  @include mq-pc {
    margin-top: 11.2rem;
  }
  @include mq-sp {
    margin-top: (50 / 375 * 100) + vw;
  }
} //section__pageNav
.pageTitle {
  @include mq-pc {
    margin-bottom: 10.2rem;
  }
  @include mq-sp {
    margin-bottom: (20 / 375 * 100) + vw;
    text-align: right;
  }
}
.breadcrumbs {
  display: flex;
  position: relative;
  z-index: 2;
  @include mq-pc {
    margin-bottom: 11.8rem;
  }
  @include mq-sp {
    margin-top: (12 / 375 * 100) + vw;
    margin-bottom: (44 / 375 * 100) + vw;
    justify-content: flex-end;
  }
  .breadcrumbsItem {
    letter-spacing: 0.1em;
    display: inline-flex;
    @include mq-pc {
      font-size: 1.4rem;
    }
    @include mq-sp {
      font-size: (13 / 375 * 100) + vw;
    }
    a {
      @include fadeover;
      color: clr(text);
    }
    &.-current {
      // color: clr(gray);
    }
    &.-deco {
      position: relative;
      &:after {
        content: "";
        width: 2.9rem;
        height: 1px;
        background-color: clr(green);
        display: inline-block;
        top: 50%;
        position: absolute;
        @include mq-pc {
          margin-left: 5.5rem;
        }
        @include mq-sp {
          margin-left: (55 / 375 * 100) + vw;
        }

        // @media screen and (min-width: 734px) and (max-width: $min-basis) {
        //   margin-left: (95 / 375 * 100) + vw;
        // }
        // @media screen and (max-width: 733px) {
        //   margin-left: 9rem;
        // }
      }
      &:nth-child(2) {
        &:after {
          @include mq-pc {
            margin-left: 7.4rem;
          }
        }
      }
    } //deco
    &:not(:last-child) {
      margin-right: 5.1rem;
    }
  } //breadcrumbsItem
} //breadcrumbs
.btnWrap.-first {
  @include mq-pc {
    margin-top: (55 / 1440 * 100) + vw;
    margin-bottom: (20 / 1440 * 100) + vw;
  }
  @media screen and (min-width: $wide-basis) {
    margin-top: 6.4rem;
    margin-bottom: 2rem;
  }
  @include mq-sp {
    margin-top: (19 / 375 * 100) + vw;
    margin-bottom: (10 / 375 * 100) + vw;
  }
} //btnWrap
.breadcrumbsBtnWrap {
  @include mq-pc {
    margin-bottom: 11.8rem;
  }
  @include mq-sp {
    display: flex;
    justify-content: end;
    margin-bottom: (120 / 375 * 100) + vw;
  }
  .breadcrumbsWrap {
    @include mq-pc {
      flex: 1;
      margin-right: 5rem;
    }
  }
  .breadcrumbs {
    margin-bottom: 0;
    flex-wrap: wrap;
  } //breadcrumbs
  .btnWrapBox {
    @include mq-pc {
      margin-left: auto;
      max-width: 34.6rem;
      width: 100%;
    }
    .btnWrap {
      &:last-child {
        @include mq-pc {
          margin-top: (20 / 1440 * 100) + vw;
        }
        @media screen and (min-width: $wide-basis) {
          margin-top: 2rem;
        }
      }
    } //btnWrap
  } //btnWrapBox
} //breadcrumbsBtnWrap
_:lang(x) + _:-webkit-full-screen-document,
.-deco:after {
  @include mq-pc {
    margin-left: 5.5rem !important;
  }
  @include mq-sp {
    margin-left: 5.5rem !important;
    // margin-left: 8.8rem !important;
  }
}
