/* utility CSS */
/** =6
 * ========================================
 * list styles
 * ========================================
 */

ul {
  counter-reset: cntOl;
  li {
    list-style-type: none;
    font-weight: inherit;
  }
  &.disc {
    li {
      position: relative;
      padding-left: 1.25em;

      &::before {
        display: inline-block;
        position: absolute;
        top: 0.9em;
        left: 0;
        width: 0.5em;
        height: 0.5em;
        margin-top: -0.25em;
        // margin-right: .7em;
        // margin-left: -1.2em;
        border-radius: 50%;
        background-color: clr(text);
        content: "";
        @include mq-sp {
          margin-top: -0.125em;
        }
      }
    }
    &ColorMain {
      @extend .disc;
      li::before {
        background-color: clr(text);
      }
    }
  }
}
ol {
  counter-reset: cntOl;
  li {
    position: relative;
    padding-left: 2em;
    list-style-type: none;
    font-weight: inherit;
    // font-family: $enfont;
    &::before {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 1.5em;
      color: inherit;
      // font-family: $enfont !important;
      font-weight: bold;
      text-align: right;
      white-space: nowrap;
      counter-increment: cntOl;
      content: counter(cntOl) ".";
    }
  }
  &.listNumColorMain {
    li::before {
      color: clr(main);
    }
  }
  &.listNumCircleColorMain {
    li::before {
      top: 0.1em;
      width: 1.75em;
      padding-top: 0.1em;
      border-radius: 50%;
      background-color: clr(main);
      font-size: 0.9em;
      color: clr(text);
      text-align: center;
      line-height: 1.65em;
      content: counter(cntOl);
    }
  }
  &.listParentheses {
    margin: 0;
    li {
      list-style-type: none;
      list-style-position: inside;
      counter-increment: cnt;
      &:before {
        display: marker;
        content: "(" counter(cnt) ") ";
      }
    }
  }
}
/**
* dl
* ----------------------------------
*/
.listDefAlternate {
  .itemTitle {
    font-weight: bold;
    &:nth-of-type(odd) {
      background-color: clr(gray);
      color: clr(text);
    }
  }
  .itemText {
    &:nth-of-type(odd) {
      background-color: clr(gray);
      color: clr(text);
    }
  }

  @include mq-sp {
    .itemTitle {
      padding: 0.25em 0.75em 0;
    }
    .itemText {
      padding: 0 0.75em 0.25em;
    }
    &.is-spHorizon {
      display: flex;
      flex-wrap: wrap;

      .itemTitle {
        display: flex;
        align-items: center;
        width: 30%;
        padding: 0.25em 1em;
        box-sizing: border-box;
        font-size: 90%;
      }
      .itemText {
        width: 70%;
        padding: 0.25em 1em;
        box-sizing: border-box;
      }
    }
  }
  @include mq-sp {
    display: flex;
    flex-wrap: wrap;

    .itemTitle {
      display: flex;
      align-items: center;
      width: 30%;
      padding: 0.25em 1em;
      box-sizing: border-box;
      font-size: 90%;
    }
    .itemText {
      width: 70%;
      padding: 0.25em 1em;
      box-sizing: border-box;
    }
  }
}
