/* utility CSS */
/* Font
------------------------------------------------------------------ */
.u-mainColor {
  color: clr(main) !important;
}
.u-subColor {
  color: clr(sub) !important;
}

.u-white {
  color: clr(white) !important;
}

.u-gray {
  color: clr(gray) !important;
}

.u-black {
  color: clr(black) !important;
}

.u-red {
  color: clr(red) !important;
}

/* Text Decoration */
.u-fw300 {
  font-weight: 300 !important;
}

.u-fw400 {
  font-weight: 400 !important;
}

.u-fw500 {
  font-weight: 500 !important;
}

.u-fw600 {
  font-weight: 600 !important;
}

.u-fw700 {
  font-weight: 700 !important;
}

.u-fNormal {
  font-weight: normal !important;
}

.u-bold {
  font-weight: bold !important;
}

.u-small {
  font-size: 0.8em !important;
}

.u-large {
  font-size: 1.5em !important;
}

.u-underline {
  text-decoration: underline !important;
}

/* Text Size */
.u-txt9 {
  font-size: 9px !important;
}

.u-txt10 {
  font-size: 10px !important;
}

.u-txt11 {
  font-size: 11px !important;
}

.u-txt12 {
  font-size: 12px !important;
}

.u-txt13 {
  font-size: 13px !important;
}

.u-txt14 {
  font-size: 14px !important;
}

.u-txt15 {
  font-size: 15px !important;
}

.u-txt16 {
  font-size: 16px !important;
}

.u-txt17 {
  font-size: 17px !important;
}

.u-txt18 {
  font-size: 18px !important;
}

.u-txt19 {
  font-size: 19px !important;
}

.u-txt20 {
  font-size: 2px !important;
}

.u-txt21 {
  font-size: 21px !important;
}

.u-txt22 {
  font-size: 22px !important;
}

.u-txt23 {
  font-size: 23px !important;
}

.u-txt24 {
  font-size: 24px !important;
}

.u-txt25 {
  font-size: 25px !important;
}

.u-txt26 {
  font-size: 26px !important;
}

.u-txt27 {
  font-size: 27px !important;
}

.u-txt28 {
  font-size: 28px !important;
}

.u-txt29 {
  font-size: 29px !important;
}

.u-txt30 {
  font-size: 30px !important;
}

/* LineHeight */
.u-lh1 {
  line-height: 1 !important;
}

.u-lh12 {
  line-height: 1.2 !important;
}

.u-lh15 {
  line-height: 1.5 !important;
}
.u-lh16 {
  line-height: 1.6 !important;
}
.u-lh20 {
  line-height: 2 !important;
}
.u-ls60 {
  letter-spacing: 0.06em !important;
}
.u-ls0 {
  letter-spacing: 0 !important;
}
.u--ls01 {
  letter-spacing: -0.01em;
}
.u-indent {
  padding-left: 1em !important;
  text-indent: -1em !important;
}

.u-fs60per {
  font-size: 60%;
}
.u-fs66per {
  font-size: 66%;
}
.u-fs70per {
  font-size: 70%;
}
.u-fs80per {
  font-size: 80%;
}
