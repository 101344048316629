@charset "UTF-8";

/* header
============================================================= */
.headerWrap {
  position: relative;
  z-index: 3;
  @include mq-pc {
    height: 9rem;
  }
  @include mq-sp {
    height: ($headerH_sp) + vw;
  }
}
#header {
  position: relative;
  z-index: 100;
  top: 0;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: clr(bgColor);
  @include mq-pc {
    height: $headerH_pc;
  }
  .gNavItem {
    @include mq-pc {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
  &.-headerDefault {
    transition: transform 0.6s;
    background-color: clr(bgColor);
    width: 100%;
  }
  &.-headerFixed {
    animation: nyuru 0.5s;
    transition: transform 0.6s;
    position: fixed;
    background-color: clr(bgColor);
    z-index: 2;
    width: 100%;
  }
  &.-headerGnavChildver {
    animation: unset;
    // position: fixed;
  }
  @at-root {
    .-spMenuOpen & {
      position: fixed;
      height: 100vh;
      width: 100%;
      background-color: clr(bgColor);
      z-index: 1;
    }
  }
} //#header

.header__inner {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  width: 100%;
  // background-color: red;
  height: 100%;
}
@include mq-pc {
  .gNav {
    .-active,
    .-gNavHover {
      &:before {
        content: "";
        width: 108px;
        height: 4px;
        background-color: clr(green);
        display: inline-block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: 3s;
      }
    }
    .-active {
      &:before {
        opacity: 1;
      }
    }
    .gNavItem {
      &:hover:before {
        opacity: 1;
      } //hover
    } //gNavItem
  }
}
.headerLogo_spMenuWrap {
  display: flex;
  align-items: center;
  @media screen and (min-width: ($min-basis + 1)) and (max-width: 885px) {
    padding-top: 1rem;
  }
  @include mq-sp {
    // position: absolute;
    height: ($headerH_sp) + vw;
    width: 100%;
    // top: (9 / 375 * 100) + vw;
  }
}

.header__logo {
  font-size: 0;
  @include mq-pc {
    // left: 73px;
    left: (73 / 1440 * 100) + vw;
  }
  @include mq-sp {
    left: $mv_rl-sp;
  }
  &:hover {
    @include fadeover;
    cursor: pointer;
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
  }

  @at-root {
    #header.-scroll &,
    #header.-megaOpen & {
    }

    #header.-scroll & {
      // opacity: 0;
      // pointer-events: none;
    }

    .-spMenuOpen & {
      background-size: auto 100%;
      // position: relative;
      z-index: 100;
      pointer-events: all;
      position: absolute;

      img {
        opacity: 0;
      }
    }
  }
  &.-defaultImg {
    background: url(/assets/img/common/logo_header.svg) no-repeat left 0;
    background-size: contain;

    @include mq-pc {
      position: relative;
      width: 21.573rem;
      // height: 23.064rem;
      height: 28rem;
      // width: 215.73px;
      // height: 230.64px;
      // top: (121.4 / 1440 * 100) + vw;
      // top: 0;
      // top: 3.14rem;
      top: 10.5rem;
    }
    // @media screen and (min-width: 768px) and (max-width: 890px) {
    //   width: (120 / 1440 * 100) + vw;
    //   height: (290.63 / 1440 * 100) + vw;
    // }
    @include mq-sp {
      position: absolute;
      width: (62 / 375 * 100) + vw;
      height: (230.64 / 375 * 100) + vw;
      top: (15 / 375 * 100) + vw;
    }
  }
  &.-fixedImg {
    @include mq-pc {
      // position: absolute;
      position: relative;
      top: unset;
      // width: 19.573rem;
      width: 26rem;
      // width: 21.573rem;
      // height: 7.44rem;
      height: 8rem;
      // height: 23.064rem;
      background: url(/assets/img/common/logo_headerFixed_pc.svg) no-repeat left
        0;
      background-size: contain;
      // top: (31.4 / 1440 * 100) + vw;
    }
    @media screen and (min-width: ($min-basis + 1)) and (max-width: 885px) {
      width: 18.573rem;
    }
    // @media screen and (min-width: 768px) and (max-width: 890px) {
    //   width: (275.73 / 1440 * 100) + vw;
    //   height: (134.4 / 1440 * 100) + vw;
    //   top: (10.4 / 1440 * 100) + vw;
    // }
    @include mq-sp {
      position: absolute;
      width: (195 / 375 * 100) + vw;
      height: (74.4 / 375 * 100) + vw;
      background: url(/assets/img/common/logo_headerFixed_pc.svg) no-repeat left
        0;
      top: (9 / 375 * 100) + vw;
      background-size: contain;
    }
  }
  @include mq-sp {
    @at-root {
      .-spMenuOpen & {
        background: url(/assets/img/common/logo_header.svg) no-repeat left 0 !important;
        background-size: contain !important;
        width: (62 / 375 * 100) + vw !important;
        height: (230.64 / 375 * 100) + vw !important;
      }
    }
  }
}

.header__nav {
  opacity: 0;
  // scroll-behavior: smooth;
  // overflow-y: scroll;
  // background-color: clr(bgColor);
  left: 0;
  top: 0;
  z-index: 99;
  transition-duration: $base-duration / 2;
  display: flex;
  @include mq-pc {
    width: 100%;
    // height: 100%;
    opacity: 1;
    max-width: $header-inner-pc;
    margin-left: auto;
    margin-right: auto;
    // padding-right: $header-lr-pc;
    padding-left: $header-lr-pc;
    padding-right: (162 / 1440 * 100) + vw;
    height: 100%;
  }
  @media screen and (min-width: $wide-basis) {
    max-width: $header-inner-wide;
    // padding-right: $header-lr-wide;
    padding-right: (195 / 1440 * 100) + vw;
    padding-left: $header-lr-wide;
  }
  @include mq-sp {
    pointer-events: none;
    // height: 100%;
    position: fixed;
    width: 100vw;
    align-items: center;
    justify-content: center;
    background-color: clr(bgColor);
  }
  @at-root {
    .-spMenuOpen & {
      opacity: 1;
      pointer-events: inherit;
      background-color: clr(bgBeigeColor);
      height: 100%;
    }
  }
}
.gnavWrap {
  @include mq-sp {
    // padding-top: 8.133vw;
    // max-width: 44vw;
    // margin-left: auto;
    // margin-right: auto;
    display: flex;
    justify-content: end;
    margin-top: (110 / 375 * 100) + vw;
  }
}

.header__navInner {
  width: 100%;
  white-space: nowrap;
  @include mq-sp {
    padding-right: $content_inner_rl-sp;
    // max-width: 16.9rem;
    // margin: auto;
    display: flex;
    // align-content: center;
    justify-content: flex-end;
    overflow: hidden;
    overflow-y: scroll;
    height: 100%;
  }
  @include mq-sp {
    &::before {
      // background-color: clr(bgColor);
      content: "";
      width: 100%;
      height: vw(90);
      position: fixed;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }

  > * {
    position: relative;
    z-index: 0;
  }
}
.-spMenuOpen {
  .header__navInner:before {
    display: none;
  }
}
/* spMenuBtn */

.spMenuBtn {
  position: absolute;
  // top: 50%;
  // transform: translateY(-50%);
  display: flex !important;
  right: 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  z-index: 10000000;
  margin-left: 3.9rem;
  // background-color: pink;
  @include mq-pc {
    width: 1.944vw;
    height: 0.556vw;
    opacity: 0;
    // margin-top: -0.25rem;

    // margin-top: 6px;
  }
  @media screen and (min-width: $wide-basis) {
    width: 2.8rem;
    height: 0.8rem;
  }

  @include mq-sp {
    margin-left: auto;
    width: (55 / 375 * 100) + vw;
    height: (20 / 375 * 100) + vw;
    opacity: 1;
    right: $mv_rl-sp;
    // top: (25 / 375 * 100) + vw;
    top: (36.6 / 375 * 100) + vw;
  }
  &::before,
  &::after {
    display: none !important;
  }
}

.spMenuBtn__icon {
  display: inline-flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  span {
    background-color: #000;
    height: 1px;
    display: inline-block;
    transition: all 0.25s;
    position: absolute;
    right: 0;
    &.first {
      top: 0;
      width: 50%;
    }
    &.second {
      top: 50%;
      width: 100%;
    }
    &.last {
      bottom: 0;
      width: 50%;
    }
  }
}
.-spMenuOpen {
  .spMenuBtn {
    @include mq-sp {
      // margin-left: auto;
      width: (31 / 375 * 100) + vw;
      height: (31 / 375 * 100) + vw;
    }
  }
  .spMenuBtn__icon {
    span {
      background-color: clr(text);
      // bottom: 0;
      position: absolute;
      // top: 3.75px;
      width: 100%;
      top: 50%;
      &.first {
        transform: rotate(-45deg);
      }
      &.second {
        display: none;
      }
      &.last {
        transform: rotate(45deg);
      }
    }
  }
} //-spMenuOpen spMenuBtn__icon
.header__navInner {
  // opacity: 0;
  @include mq-pc {
    opacity: 1;
  }
  // transition-duration: $base-duration / 12;
}
.-isFadeIn {
  opacity: 1;
}
@include mq-pc {
  @keyframes nyuru {
    0% {
      margin-top: -(130 / 1440 * 100) + vw;
    }
    100% {
      margin-top: 0;
    }
  }
}
@include mq-sp {
  @keyframes nyuru {
    0% {
      margin-top: -(76 / 375 * 100) + vw;
    }
    100% {
      margin-top: 0;
    }
  }
}
