@charset "UTF-8";

html,
body {
  height: 100%;
}
/*
 base-font-size : 10px;
 62.5% = 10px = 1em = 1rem
 (Default)
*/
html {
  font-size: 62.5%;
}
body {
  word-break: break-word;
  font-family: $basefont;
  -webkit-text-size-adjust: 100%;
  letter-spacing: 0.01em;
  word-wrap: break-word;
  color: clr(main);
  background-color: clr(bgColor);
  font-weight: 500;
  position: relative;

  @include mq-pc {
    font-size: percentage($base-font-size * 0.1);
    line-height: 2.25;
  }
  @include mq-sp {
    font-size: vw(strip-unit($base-font-size-sp));
    line-height: 2;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  // @include mq-pc {
  //   font-size: rem(strip-unit($base-font-size));
  //   line-height: $base-line-height;
  // }

  // @include mq-sp {
  //   font-size: vw(strip-unit($base-font-size-sp));
  //   line-height: $base-line-height-sp;
  // }

  &.-spMenuOpen {
    @include mq-pc {
      overflow-y: hidden;
    }
    @include mq-sp {
      width: 100vw;
      height: 100vh;
      overflow: hidden;
    }
  } //spMenuOpen
  &.-overflowHidden {
    overflow: hidden;
  } //overflowHidden
}

h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

#wrapper {
}

#main {
  display: block;

  @include mq-sp {
    overflow: hidden;
  }
}

/* link
============================================================= */
.cursorover {
  cursor: pointer;
}
.fadeover {
  @include fadeover;
}
.fadeoverNon {
  &:hover {
    opacity: 1;
  }
}
// a {
//   color: clr(text);
//   text-decoration: underline;

//   &:visited,
//   &:hover {
//     color: clr(text);
//     text-decoration: none;
//   }

//   &.-hoverBorder {
//     @include mq-hover {
//       display: inline-block;
//       position: relative;

//       &::before {
//         background-color: clr(text);
//         content: "";
//         width: 0;
//         height: 1px;
//         position: absolute;
//         left: calc(50% - #{rem(4)});
//         bottom: rem(-11);
//         transition-duration: 0.3s;
//       }

//       &:hover {
//         &::before {
//           width: rem(10);
//         }
//       }
//     }
//   }
// }

/* image
============================================================= */

img {
  height: auto;
  line-height: 1;
  max-width: 100%;
  vertical-align: top;
}
.objectfit {
  height: 100%;
  img {
    object-fit: unset;
    height: 100%;
    width: 100%;
  }

  &.-cover {
    img {
      object-fit: cover;
    }
  }
  &.-contain {
    img {
      object-fit: contain;
    }
    .-containHeightAuto {
      height: auto;
    }
  }
}
@include mq-pc {
  .objectfit_pc {
    height: 100%;
    img {
      object-fit: unset;
      height: 100%;
    }

    &.-cover {
      img {
        object-fit: cover;
      }
    }
    &.-contain {
      img {
        object-fit: contain;
      }
    }
  }
}

// table th img,
// table td img {
//   vertical-align: middle;
// }

/* form
============================================================= */

input,
textarea,
label {
  vertical-align: middle;
}

/* display
============================================================= */

.-pcItem {
  @include mq-pc {
    display: block;
  }
  @include mq-sp {
    display: none !important;
  }
}

.-spItem {
  @include mq-pc {
    display: none !important;
  }
  @include mq-sp {
    display: block;
  }
}
.-spTelItem {
  @include mq-pc {
    pointer-events: none;
  }
}

/* margin
============================================================= */

$mspan: 5;

// mt0 〜 mt60
@for $i from 0 through 12 {
  .-mt#{$i*$mspan} {
    @include mq-pc {
      margin-top: rem($mspan * $i);
    }
    @include mq-sp {
      margin-top: vw($mspan * $i);
    }
  }
}

// mb0 〜 mb30
@for $i from 0 through 6 {
  .-mb#{$i*$mspan} {
    @include mq-pc {
      margin-bottom: rem($mspan * $i);
    }
    @include mq-sp {
      margin-bottom: vw($mspan * $i);
    }
  }
}

// mr0 〜 mr20
@for $i from 0 through 4 {
  .-mr#{$i*$mspan} {
    @include mq-pc {
      margin-right: rem($mspan * $i);
    }
    @include mq-sp {
      margin-right: vw($mspan * $i);
    }
  }
}

// ml0 〜 ml20
@for $i from 0 through 4 {
  .-ml#{$i*$mspan} {
    @include mq-pc {
      margin-left: rem($mspan * $i);
    }
    @include mq-sp {
      margin-left: vw($mspan * $i);
    }
  }
}

/* padding
============================================================= */

@include mq-pc {
  .-pcPdg {
    padding-left: rem($pc-pdg);
    padding-right: rem($pc-pdg);
  }
}

@include mq-sp {
  .-spPdg {
    padding-left: vw($sp-pdg);
    padding-right: vw($sp-pdg);
  }
}

/* text-align
============================================================= */

.-textRight {
  text-align: right;
}

.-textCenter {
  text-align: center;
}

.-textLeft {
  text-align: left;
}

/* block / 
============================================================= */
.displayBlock {
  display: block;
}
.displayInlineBlock {
  display: inline-block;
}
