.pageNav {
  &__inner {
    // background-color: clr(bgBeigeColor);
  } //inner
  &__itemList {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq-sp {
      flex-wrap: wrap;
    }
  } //__itemList
  &__item {
    width: 100%;
    // @include mq-sp {
    //   width: 100%;
    // }
    // @include mq-pc {
    //   width: (346 / 1440 * 100) + vw;
    // }
    // @media screen and (min-width: $wide-basis) {
    //   max-width: 34.6rem;
    //   width: 100%;
    // }
    &:not(:last-child) {
      @include mq-pc {
        margin-right: (15 / 1440 * 100) + vw;
      }
      @media screen and (min-width: $wide-basis) {
        margin-right: 1.5rem;
      }
      @include mq-sp {
        margin-bottom: (20 / 375 * 100) + vw;
      }
    }

    a {
      line-height: 1.66666;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @include mq-pc {
        font-size: 1.8rem;
        padding: 1rem 0;
        border: 1px solid clr(green);
      }
      @include mq-sp {
        font-size: (18 / 375 * 100) + vw;
        padding: (10 / 375 * 100) + vw 0;
        border: (1 / 375 * 100) + vw solid clr(green);
      }
      &:hover {
        opacity: 1;
        color: white;
        background-color: clr(green);
      } //hover
    } //a
    &.-current {
      a {
        pointer-events: none;
        opacity: 1;
        color: white;
        background-color: clr(green);
      }
    } //current
  } //__item
}
