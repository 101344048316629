@charset "UTF-8";

/* sideContents
============================================================= */
@include mq-pc {
  .sideMainWrap {
    display: flex;
  } //sideMainWrap
  .-sticky {
    position: sticky;
    top: 0;
  }
}
.sideContents {
  // background-color: pink;
  color: clr(txt);
  font-family: $basefont;

  a {
    color: clr(txt);
    @include fadeover;
  }
  &.-con {
    &:not(:last-child) {
      @include mq-pc {
        margin-bottom: 2rem;
      }
      @include mq-sp {
        margin-bottom: (15 / 375 * 100) + vw;
      }
    }
  }
  &MainConWrap {
    @include mq-pc {
      display: flex;
      flex-direction: row-reverse;
    }
  } //MainConWrap
  &.-wrap {
    @include mq-pc {
      max-width: 12rem;
      width: 100%;
      margin-left: auto;
    }
    @include mq-sp {
      z-index: 1;
      background-color: clr(bgColor);
      width: (120 / 375 * 100) + vw;
      margin-left: auto;
      margin-bottom: (43 / 375 * 100) + vw;
    }
  } //wrap
  &.-title {
    width: 100%;
    text-align: left;
    color: clr(text) !important;
    span {
      color: clr(text) !important;
    }
    @include mq-pc {
      font-size: 1.4rem;
    }
    @include mq-sp {
      font-size: (14 / 375 * 100) + vw;
    }
    &.-borderBottom {
      @include mq-pc {
        padding-bottom: 0.5rem;
      }
      @include mq-sp {
        padding-bottom: (7 / 375 * 100) + vw;
      }
    } //borderBottom
  } //-title
  &.-list {
    @include mq-pc {
      font-size: 1.4rem;
      margin-top: 1rem;
    }
    @include mq-sp {
      font-size: (14 / 375 * 100) + vw;
      margin-top: (18 / 375 * 100) + vw;
    }
    &.-borderBottom {
      @include mq-pc {
        padding-bottom: 2.5rem;
      }
      @include mq-sp {
        padding-bottom: (25 / 375 * 100) + vw;
      }
    } //borderBottom
  } //list
  .-borderBottom {
    border-bottom: 1px solid clr(green);
  }
  .-borderBottomNon {
    border-bottom: none !important;
  }
  &.-item {
    &:not(:last-child) {
      @include mq-pc {
      }
    }
  } //-item
  .-arrowDown {
    position: relative;
    &:after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      @include mq-pc {
        border-left: 0.4rem solid transparent;
        border-right: 0.4rem solid transparent;
        border-top: 0.8rem solid clr(green);
      }
      @include mq-sp {
        border-left: (4 / 375 * 100) + vw solid transparent;
        border-right: (4 / 375 * 100) + vw solid transparent;
        border-top: (7 / 375 * 100) + vw solid clr(green);
        margin-top: -1vw;
      }
    }
  }
  .-arrowUp {
    &:after {
      border-top: unset;
      @include mq-pc {
        border-bottom: 0.8rem solid clr(green);
      }
      @include mq-sp {
        border-bottom: (7 / 375 * 100) + vw solid clr(green);
      }
    }
  }

  button {
    @include fadeover;
    &:hover {
      cursor: pointer;
    }
  }

  @include mq-pc {
    //moreボタン実装
    .-categoryItem {
      opacity: 1;
    }
    .-categoryItem.is-hidden {
      opacity: 0;
      height: 0;
      margin: 0;
    }
  }
} //sideContents

// @include mq-sp {
//   _::-webkit-full-page-media,
//   _:future,
//   :root .sideContents.-spWrap .sideContents.-con {
//     top: -(96 / 375 * 100) + vw;
//   }
//   _::-webkit-full-page-media,
//   _:future,
//   :root .l-wrapper {
//     padding-top: (96 / 375 * 100) + vw;
//   }
// }
