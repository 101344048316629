@charset "UTF-8";

/* pagination
============================================================= */

.pager {
  @include mq-pc {
    margin-top: 3.1rem;
  }
  @include mq-sp {
    margin-top: (51.25 / 375 * 100) + vw;
  }
  &List {
    justify-content: center;
    line-height: 1;
    text-align: center;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  } //List
  &Item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    @include mq-pc {
      font-size: 1.8rem;
      margin-left: 1rem;
      margin-right: 1rem;
    }
    @include mq-sp {
      font-size: (18 / 375 * 100) + vw;
      margin-left: (10 / 375 * 100) + vw;
      margin-right: (10 / 375 * 100) + vw;
    }
    a {
      @include fadeover;
      color: clr(gray);
    }

    &.-currentUnder {
      pointer-events: none;
      position: relative;
      font-weight: 600;
      color: clr(text);
      &:after {
        bottom: -1rem !important;
      }
    }
  }
  .pagerList {
    position: relative;
  }
}
.readMoreNextPrevNavWrap {
  @include mq-pc {
    display: flex;
    align-items: center;
  }
}
.nextPrevNav {
  display: flex;
  margin-left: auto;
  @include mq-pc {
    font-size: 1.8rem;
  }
  @include mq-sp {
    font-size: (18 / 375 * 100) + vw;
  }
  a {
    display: block;
  }
  .slash {
    color: clr(green);
    @include mq-pc {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    @include mq-sp {
      margin-left: (5 / 375 * 100) + vw;
      margin-right: (5 / 375 * 100) + vw;
    }
  }
  .nextPrevNavItem {
  } //nextPrevNavItem
} //nextPrevNav
